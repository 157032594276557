import React from 'react';

import Layout from '../components/Layout';
import Hiw from "../components/hiw";
import Card from "../components/card";
import Testimonials from "../components/Testimonials";
import {Helmet} from "react-helmet";

export class InsurancePageTemplate extends React.Component {
  componentDidMount() {
    this.content();
  }

  content() {
    if (window) {
      let params = new URLSearchParams(window.location.search);

      const validcompany = ['aetna', 'anthem', 'blueshield', 'healthcare', 'medicare', 'unitedhealthcare'];
      const detailname = ['Aetna', 'Anthem Blue Cross', 'Blue Shield', 'HealthCare Partners', 'Medicare CA South', ' United Healthcare'];

      if ((validcompany.indexOf(params.get('name'))) == -1) {
      } else {
        window.document.querySelectorAll('.name').forEach((element) => { element.innerHTML = ' ' + detailname[(validcompany.indexOf(params.get('name')))] })
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.content();
  }

  render() {
    return (
      <main role="main" className="insurance-page">
        <Helmet>
          <title>Insurance</title>
        </Helmet>
        <div>
          {/* Begin Banner */}
          <div className="jumbotron why-pete mb-0" style={{"backgroundImage": "linear-gradient(90deg, rgba(146, 43, 184, 0.69), rgba(246, 31, 128, 0.69), rgba(254, 164, 120, 0.69)), url('/assets/newimg/fortherapistbanner.png')"}}>
            <div className="container text-light">
              <div className="row">
                <div className="col-md-7">
                  <p className="display-5 text-white mb-3 text-left" style={{fontSize: '2rem'}}>
                    PeteHealth is in-network with<strong className="name" > United Healthcare</strong>
                  </p>
                  <p className="text-left text-white">
                    Our experienced physical therapists are here to provide <strong className="name">United Healthcare</strong> patients with the physical therapy they need, right when they need it — all with just a standard co-pay. PeteHealth has no waiting lists — and no waiting rooms. Get in touch to schedule your first on-demand physical therapy session with the best physical therapy provider in Los Angeles and Orange Country. </p>
                  <br />
                  <a className="btn btn-gr pl-3 pr-3 visit-btn mr-3 fw-6 schedule" data-toggle="modal" data-target="#FormModalCenter" role="button">Schedule visit</a>
                  <br />
                  <br />
                  <span style={{fontSize: '17px', fontWeight: 'bold'}}>or call
            <a className="text-white" href="tel:+18888590145" role="button"> 888-859-0145</a>
          </span>
                </div>
                <div className="col-md-5" />
              </div>
            </div>
          </div>
          {/* End Banner */}
          {/* Begin Second section */}
          <div className="container features card ca-shadow-sm pt-5 pb-3 mt-n4 mb-5 px-4 rounded-2">
            <Card />
          </div>
          {/* End Second section */}
          {/* Begin How it works */}
          <div className="container pt-2 pb-5 hiw">
            <Hiw />
          </div>
          {/* End How it works */}
          {/* Begin Testimonials */}
          <div className="container-fluid bg-gray mt-4 pt-5 pb-5 text-center">
            <div className="container pb-17">
              <h2 className="py-4"> What others are saying about Pete </h2>
              <p className="pb-4">The list of happy patients just keeps getting longer and longer.</p>
              <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <Testimonials />
              </div>
              <div id="mob-carousel" className="carousel slide" data-ride="carousel"> </div>
            </div>
          </div>
          {/* End Testimonials */}
          {/* Begin Compare us to others */}
          <div className="container text-center pt-5 pb-5 compare mt-n17">
            <h2 className="py-5">Compare us to others</h2>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
            <div className="row">
              <div className="col-md-11 mx-auto  ca-shadow-sm rounded-2 bg-white px-5">
                <table className="table table-borderless">
                  <thead>
                  <tr className="py-4">
                    <th scope="col" />
                    <th className="text-uppercase" scope="col"><img className="widthmobileimg" src="/assets/newimg/logo.png" style={{width: '100px'}} alt="" /></th>
                    <th className="text-uppercase" scope="col">
                      <h2 className="theme-text-color widthmobileimg" style={{fontSize: '1.2rem'}}>Clinic</h2>
                    </th>
                  </tr></thead>
                  <tbody className="text-left">
                  <tr className="bg-gray">
                    <td className="text-uppercase theme-text-color" colSpan={3} style={{color: '#00234e !important'}}>QUALITY CARE</td>
                  </tr>
                  <tr className="border-bottom">
                    <td className="pl-2 padding-pl" style={{paddingTop: '24px !important', paddingBottom: '20px !important'}}>
                      Exclusive treatment with one therapist for the entire session</td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/newimg/tick.png" alt="" /></td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/img/wrong.png" alt="" /></td>
                  </tr>
                  <tr className="border-bottom">
                    <td className="pl-2 padding-pl"  style={{paddingTop: '24px !important', paddingBottom: '20px !important'}}>All
                      equipment for treatment brought by therapist</td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/newimg/tick.png" alt="" /></td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/img/wrong.png" alt="" /></td>
                  </tr>
                  <tr className="border-bottom">
                    <td className="pl-2 padding-pl"  style={{paddingTop: '24px !important', paddingBottom: '20px !important'}}>
                      Instruction provided for how to move in home/work environment</td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/newimg/tick.png" alt="" /></td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/img/wrong.png" alt="" /></td>
                  </tr>
                  <tr>
                    <td className="pl-2 padding-pl"  style={{paddingTop: '24px !important', paddingBottom: '20px !important'}}>Schedule
                      provides for higher appointment frequency</td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/newimg/tick.png" alt="" /></td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/img/wrong.png" alt="" /></td>
                  </tr>
                  <tr className="bg-gray">
                    <td className="text-uppercase theme-text-color" colSpan={3} style={{color: '#00234e !important'}}>CONVENIENCE</td>
                  </tr>
                  <tr className="border-bottom">
                    <td className="pl-2 padding-pl"  style={{paddingTop: '24px !important', paddingBottom: '20px !important'}}>
                      Therapist comes to your home/work</td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/newimg/tick.png" alt="" /></td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/img/wrong.png" alt="" /></td>
                  </tr>
                  <tr className="border-bottom">
                    <td className="pl-2 padding-pl"  style={{paddingTop: '24px !important', paddingBottom: '20px !important'}}>
                      Insurance accepted</td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/newimg/tick.png" alt="" /></td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/newimg/tick.png" alt="" /></td>
                  </tr>
                  <tr>
                    <td className="pl-2 padding-pl"  style={{paddingTop: '24px !important', paddingBottom: '20px !important'}}>Fast
                      processing between initial booking and first appointment</td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/newimg/tick.png" alt="" /></td>
                    <td className="td-img padding-im" style={{paddingTop: '20px !important', paddingBottom: '20px !important'}}>
                      <img src="/assets/img/wrong.png" alt="" /></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row py-5">
              <div className="col">
                <div className="mb-1">
                  <a className="btn btn-gr pl-3 pr-3 fw-6 mt-4" href="#" role="button" data-toggle="modal" data-target="#FormModalCenter">Schedule visit</a>
                </div>
                <br />
                <div className="f-7 fw-6 text-dark call">or call <a className="text-dark" href="tel:888-859-0145 " role="button">888-859-0145</a>
                </div>
              </div>
            </div>
          </div>
          {/* End Compare us to others */}
        </div>

      </main>
    )
  }
};


const InsurancePage = () => {
  return (
    <Layout>
      <InsurancePageTemplate/>
    </Layout>
  )
};


export default InsurancePage;
