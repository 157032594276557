import React from 'react';

const Testimonials = () => (
  <div>
    <div className="carousel-inner">
      <div className="carousel-item active">
        <div className="row mb-md-4">
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                After shoulder labrum repair surgery, and trouble finding a physical therapist
                in my area, and I decided to try out Pete Health. That might have been the best
                decision I made in my shoulder rehab. Plus, I didn't have to drive in traffic.
              </p>
              <h3>Cassie L.</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                I am a physician that tore my left meniscus earlier this year. My PM &amp; R
                doctor recommended PT over Surgery and gave me you all as a recommendation.
                He knew I also had a busy work schedule and that you'd come to me.
              </p>
              <h3>Dr. Javier</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                My treatment sessions have been great! My therapist has taught me how to
                strengthen my core and abs to take pressure off of my back. I've been struggling with
                nerve pain and after 5 sessions, it's so much better already.
              </p>
              <h3>Don G.</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                I can't say enough good things. I love that my therapist emails me videos of the
                stretches and exercises to do in between sessions and on my own. I recommend
                Pete Health very highly to anyone experiencing back and sciatica.
              </p>
              <h3>Kendra M.</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="row mb-md-4">
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                What an amazing discovery for physical therapy! I had rotator cuff surgery, and was
                struggling to find appointments at other places, or any continuity for my post care.
                Pete Health comes to me, and it's literally the best PT I've ever had!
              </p>
              <h3>Daryl P.</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                I fell down the stairs and my doctor wrote me a referral for physical therapy. I called
                several places and they all were quite rude, actually. Our insurance is excellent but it
                takes a little effort to get all of the details. Pete Health was the friendliest.
              </p>
              <h3>Debbie S.</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                I had a torn meniscus in my left knee and had to start physical therapy.
                I started going to a physical therapist located in Hollywood, which ultimately
                closed, but that turned out to be a blessing in disguise. That's when I found
                Pete Health.
              </p>
              <h3>Myron L.</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                Pete Health is absolutely wonderful. They come to my apartment twice a week for
                my rehab sessions, PLUS they take insurance. My therapist is extremely
                knowledgeable and even brings a table for the stretching and messaging I need.
              </p>
              <h3>Karen A.</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="row mb-md-4">
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                This is the future of physical therapy. A couple of months ago, I badly
                sprained my ankle playing basketball. I was looking for just a few
                sessions with a physical therapist, and I'm glad I found Pete Health.
              </p>
              <h3>Danny R.</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                It's a great idea to have physical therapy at your own home. The results were
                great, and I was able to get treatment and to learn the exercises right in my
                own home that made healing my ankle easier.
              </p>
              <h3>Natasha B.</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                I broke my kneecap and needed surgery. My orthopedic surgeon suggested Pete Health.
                I couldn't possibly travel for therapy with a painful knee in a big brace and Pete
                Health actually comes to you.
              </p>
              <h3>Christopher W.</h3>
            </div>
          </div>
          <div className="col-md-6" style={{height: '270px'}}>
            <div className="card ca-shadow-sm border-0 p-4 py-5" style={{height: '240px'}}>
              <img src="/assets/img/5-star.png" className="text-center stars" alt="" />
              <p>
                Using massage and carefully tailored exercises tailored to my needs, Pete Health's
                physical therapist has seen me through my continuing rehabilitation. I appreciate
                how that I am never pressured to do more than I feel I can do.
              </p>
              <h3>Keisha K.</h3>
            </div>
          </div>
        </div>
      </div>
    </div><a className="carousel-control-prev ca-shadow-sm rounded-circle white-bg" href="#carouselExampleControls" role="button" data-slide="prev">←</a> <a className="carousel-control-next ca-shadow-sm rounded-circle white-bg" href="#carouselExampleControls" role="button" data-slide="next">→</a>
  </div>

);

export default Testimonials
